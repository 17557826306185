


































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { ExperienceInformation } from '@/models/experience-information.interface';

import { metadataService } from '@/services/metadata.service';

import Job from './Job.vue';
import Skill from './Skill.vue';

@Component({
  components: {
    Job,
    Skill
  }
})
export default class Experience extends Vue {
  experienceInformation: ExperienceInformation | null = null;

  created(): void {
    this.experienceInformation = metadataService.experience;
  }
}
