import { render, staticRenderFns } from "./Job.vue?vue&type=template&id=59c70e18&scoped=true&"
import script from "./Job.vue?vue&type=script&lang=ts&"
export * from "./Job.vue?vue&type=script&lang=ts&"
import style0 from "./Job.vue?vue&type=style&index=0&id=59c70e18&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59c70e18",
  null
  
)

export default component.exports