







































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { PersonalInformation } from '@/models/personal-information.model';

import InformationLine from './InformationLine.vue';

@Component({
  components: {
    InformationLine
  }
})
export default class Personal extends Vue {
  @Prop({ type: Object, required: true })
  data!: PersonalInformation;
}
