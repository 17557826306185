

























import { Component, Prop, Vue } from 'vue-property-decorator';

import { PersonalInformation } from '@/models/personal-information.model';
import { metadataService } from '@/services/metadata.service';

@Component
export default class InterestsAchievements extends Vue {
  personalInformation: PersonalInformation | null = null;

  created(): void {
    this.personalInformation = metadataService.personal;
  }
}
