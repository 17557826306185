














































import { PersonalInformation } from '@/models/personal-information.model';
import { metadataService } from '@/services/metadata.service';
import { Component, Prop, Vue } from 'vue-property-decorator';

import Personal from './Personal.vue';

@Component({
  components: {
    Personal
  }
})
export default class Header extends Vue {
  personalInformation: PersonalInformation | null = null;

  get prettyPhoneNo(): string {
    if (!this.personalInformation) {
      return '';
    }

    if (this.personalInformation.phone.startsWith('+')) {
      return `+${this.personalInformation.phone
        .substr(1)
        .match(/.{1,2}/g)
        ?.join(' ')}`;
    } else {
      return this.personalInformation.phone.match(/.{1,2}/g)?.join(' ') ?? '';
    }
  }

  created(): void {
    this.personalInformation = metadataService.personal;
  }
}
