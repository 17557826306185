








import { Component, Vue } from 'vue-property-decorator';

import Header from '@/components/Header.vue';
import Experience from '@/components/Experience.vue';
import InterestsAchievements from '@/components/InterestsAchievements.vue';

@Component({
  components: {
    Header,
    Experience,
    InterestsAchievements
  }
})
export default class App extends Vue {}
