







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InformationLine extends Vue {
  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: String, required: true })
  description!: string;
}
