
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProfessionalExperience } from '@/models/professional-experience.model';

@Component
export default class Job extends Vue {
  @Prop({ type: Object, required: true })
  data!: ProfessionalExperience;
}
